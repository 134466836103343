export const utilities = {
  data() {
    return {
      utilities: {
        electricity: {
          name: "Electricity",
          unit: "kWh",
          icon: "plug"
        },
        heat: {
          name: "Heat",
          unit: "kWh",
          icon: "thermometer-three-quarters"
        },
        energyd: {
          name: "Energy (Day)",
          unit: "kWh",
          icon: "plug"
        },
        energyn: {
          name: "Energy (Night)",
          unit: "kWh",
          icon: "plug"
        },
        energyd2: {
          name: "Energy (Day 2)",
          unit: "kWh",
          icon: "plug"
        },
        energyn2: {
          name: "Energy (Night 2)",
          unit: "kWh",
          icon: "plug"
        },
        cws: {
          name: "Cold Water",
          unit: "m3",
          icon: "tint"
        },
        hws: {
          name: "Hot Water",
          unit: "m3",
          icon: "tint"
        },
        gas: {
          name: "Gas",
          unit: "kWh",
          icon: "tint"
        },
        hhws: {
          name: "Heated Hot Water",
          unit: "kWh",
          icon: "tint"
        },
        cooling: {
          name: "Cooling",
          unit: "kWh",
          icon: "fan"
        },
        hca1: {
          name: "Heating",
          unit: "units",
          icon: "thermometer-three-quarters"
        },
        hca2: {
          name: "Heating",
          unit: "units",
          icon: "thermometer-three-quarters"
        },
        ppd: {
          name: "Energy",
          unit: "kWh",
          icon: "thermometer-three-quarters"
        }
      }
    };
  },
  methods: {
    getElement(element, utilityName) {
      return this.utilities[utilityName][element];
    }
  }
};
