<template>
  <div
    class="utility-box"
    v-bind:class="{ selected: id === activeUtility, disabled: isDisabled }"
  >
    <font-awesome-icon v-bind:icon="getElement('icon', utility)" class="icon" />
    <h3>
      {{ read }}<span v-if="isEstimate" class="readType">(E)</span>
      <span class="unit">{{ getElement("unit", utility) }}</span>
    </h3>
    <h4>{{ getElement("name", utility) }}</h4>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { utilities } from "@/components/mixins/utilities";

export default {
  name: "UtilityBlock",
  mixins: [utilities],
  props: {
    id: {
      type: Number,
      required: true
    },
    activeUtility: {
      type: Number,
      required: true
    },
    utility: {
      type: String,
      required: true
    },
    read: {
      type: [Number, String],
      required: true
    },
    isEstimate: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: true
    }
  },
  components: {
    FontAwesomeIcon
  }
};
</script>

<style lang="scss" scoped>
.utility-box {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-transition-property: initial;
  transition-property: all;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-delay: initial;
  transition-delay: 0s;
  margin-bottom: 10px;

  position: relative;
  cursor: pointer;
  padding: 10px;
  color: white;
  background: #11998e; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #22bbaf, #11998e);
  background: linear-gradient(to right, #22bbaf, #11998e);

  h3 {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: left;
    margin-left: 30px;
  }

  h4 {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .unit {
    font-size: 1rem;
    padding-left: 7px;
  }

  .readType {
    font-size: 0.8rem;
  }

  &.selected {
    cursor: default;
    background: #6b3594; /* fallback for old browsers */
    background: -webkit-linear-gradient(45deg, #6b3594, #6b3594b8);
    background: linear-gradient(45deg, #6b3594, #6b3594b8);
  }

  &.selected:hover {
    background: #6b3594; /* fallback for old browsers */
    background: -webkit-linear-gradient(45deg, #6b3594, #6b3594b8);
    background: linear-gradient(45deg, #6b3594, #6b3594b8);
  }

  &:hover {
    background: #117469; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #22bbaf, #117469);
    background: linear-gradient(to right, #22bbaf, #117469);
  }
}

.disabled {
  cursor: not-allowed;
}
</style>
